// extracted by mini-css-extract-plugin
export var ourStoriesBlock = "OurStoriesBlock-module--ourStoriesBlock--3_OOc";
export var container = "OurStoriesBlock-module--container--1wDYo";
export var list = "OurStoriesBlock-module--list--2hTZa";
export var listItem = "OurStoriesBlock-module--listItem--1_dpF";
export var link = "OurStoriesBlock-module--link--1Pr06";
export var imageWrapper = "OurStoriesBlock-module--imageWrapper--ZYYjx";
export var iconWrapper = "OurStoriesBlock-module--iconWrapper--3-gEP";
export var image = "OurStoriesBlock-module--image--1Esbc";
export var overlay = "OurStoriesBlock-module--overlay--2aKKg";
export var heading = "OurStoriesBlock-module--heading--2oIEu";
export var readingTime = "OurStoriesBlock-module--readingTime--2hPoV";
export var employeeImage = "OurStoriesBlock-module--employeeImage--3GI0J";
export var icon = "OurStoriesBlock-module--icon--2B2P8";
export var buttonWrapper = "OurStoriesBlock-module--buttonWrapper--2FPyt";
export var button = "OurStoriesBlock-module--button--3eV6N";