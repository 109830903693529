import React from "react";

import { DetailedHTMLProps } from "react";
import { GatsbyLinkProps as LinkProps } from "gatsby";
import { HTMLAttributes } from "react";
import { Link } from "gatsby";
import { Ref } from "react";

import clsx from "clsx";

import { forwardRef } from "react";

import * as classNames from "@Css/Component/Button/LinkButton.module.scss";

/**
 * @type LinkButtonProps
 */
export type LinkButtonProps = LinkProps<{}> & {
    active?: boolean;
    backgroundProps?: Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children">;
    color?: "primary" | "secondary";
    foregroundProps?: Omit<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, "children">;
    variant?: "contained" | "outlined";
};

/*
 * @TODO: Replace Ref<any> with an actual type
 */

/**
 * @const LinkButton
 */
const LinkButton = (props: LinkButtonProps, ref: Ref<any>) => {
    const {
        active,

        backgroundProps: {
            className: backgroundClassName,
            ...restBackgroundProps
        } = {},

        children,
        className,
        color = "primary",

        foregroundProps: {
            className: foregroundClassName,
            ...restForegroundProps
        } = {},

        variant = "contained",
        ...restProps
    } = props;

    return (
        <Link { ...restProps } className={ clsx(classNames.linkButton, {
            [classNames.contained]: variant == "contained",
            [classNames.outlined]: variant == "outlined",
            [classNames.primary]: color == "primary",
            [classNames.secondary]: color == "secondary",
            [classNames.active]: active
        }, className) } ref={ ref }>
            <div { ...restBackgroundProps } className={ clsx(classNames.background, backgroundClassName) }>
                { children }
            </div>
            <div { ...restForegroundProps } className={ clsx(classNames.foreground, foregroundClassName) }>
                { children }
            </div>
        </Link>
    );
};

export default forwardRef(LinkButton);
