import React from "react";

import { GatsbyImage } from "gatsby-plugin-image";

import { IGatsbyImageData } from "gatsby-plugin-image";
import { PageProps } from "gatsby";
import { Ref } from "react";

import { forwardRef } from "react";
import { useContext } from "react";
import { useLayoutEffect } from "react";
import { useTranslation } from "react-i18next";

import ThemeContext from "@Js/Context/ThemeContext";

import Seo from "@Js/Component/Seo";

import Gutenberg from "@Js/Component/Gutenberg";
import ReadingTime from "@Js/Component/ReadingTime";
import Heading from "@Js/Component/Heading";
import OurStoriesBlock from "@Js/Component/Block/OurStoriesBlock";
import WhatWeCanDoForYouBlock from "@Js/Component/Block/WhatWeCanDoForYouBlock";

import * as classNames from "@Css/Component/Page/Blog.module.scss";

/**
 * @type BlogProps
 */
export type BlogProps = PageProps & {
    pageContext: {
        blog: {
            content?: string;
            date?: string;
            featuredImage?: {
                node: {
                    altText: string;
                    localFile: {
                        childImageSharp: {
                            gatsbyImageData: IGatsbyImageData;
                        };
                    };
                };
            };
            postDetails: {
                employee?: {
                    featuredImage?: {
                        node: {
                            localFile: {
                                childImageSharp: {
                                    gatsbyImageData: IGatsbyImageData;
                                };
                            };
                        };
                    };
                    title: string;
                };
            };
            seo: {
                canonical: string;
                metaDesc: string;
                metaKeywords: string;
                metaRobotsNofollow: string;
                metaRobotsNoindex: string;
                opengraphAuthor: string;
                opengraphDescription: string;
                opengraphModifiedTime: string;
                opengraphPublishedTime: string;
                opengraphPublisher: string;
                opengraphSiteName: string;
                opengraphTitle: string;
                opengraphType: string;
                opengraphUrl: string;
                schema: {
                    raw: string;
                };
                title: string;
            };
            title: string;
        };
        postEdges: {
            node: {
                content?: string;
                id: string;
                postDetails: {
                    employee?: {
                        featuredImage?: {
                            node: {
                                localFile: {
                                    childImageSharp: {
                                        gatsbyImageData: IGatsbyImageData;
                                    };
                                };
                            };
                        };
                    };
                    thumbnail?: {
                        localFile: {
                            childImageSharp: {
                                gatsbyImageData: IGatsbyImageData;
                            };
                        };
                    };
                };
                title: string;
                uri: string;
            };
        }[];
    };
};

/**
 * @const Blog
 */
const Blog = (props: BlogProps, ref: Ref<HTMLElement>) => {
    const {
        pageContext
    } = props;

    const { t } = useTranslation();

    const {
        setHeaderBackground,
        setIsLogoColorless,
        setIsMenuColorless
    } = useContext(ThemeContext);

    useLayoutEffect(() => {
        setHeaderBackground("#FFFFFF");

        setIsLogoColorless(false);
        setIsMenuColorless(false);
    }, []);

    return (
        <>
            <Seo { ...pageContext.blog.seo } />
            <section className={ classNames.blog } ref={ ref }>
                <div className={ classNames.container }>
                    <div className={ classNames.container }>
                        <ReadingTime className={ classNames.readingTime } content={ pageContext.blog.content ?? "" } />
                        { pageContext.blog.postDetails.employee && pageContext.blog.postDetails.employee.featuredImage && (
                            <>
                                <GatsbyImage
                                    alt={ pageContext.blog.title }
                                    className={ classNames.employeeImage }
                                    image={ pageContext.blog.postDetails.employee.featuredImage.node.localFile.childImageSharp.gatsbyImageData }
                                />
                                <span className={ classNames.employeeName }>{ pageContext.blog.postDetails.employee.title }</span>
                            </>
                        ) }
                    </div>
                    <Heading>{ pageContext.blog.title }</Heading>
                    { pageContext.blog.featuredImage &&
                        <GatsbyImage
                            alt={ pageContext.blog.featuredImage.node.altText || pageContext.blog.title }
                            className={ classNames.featuredImage }
                            image={ pageContext.blog.featuredImage.node.localFile.childImageSharp.gatsbyImageData }
                        />
                    }
                </div>
                { pageContext.blog.content && (
                    <Gutenberg className={ classNames.gutenberg } content={ pageContext.blog.content } />
                ) }
                <div className={ classNames.ourStoriesBlockWrapper }>
                    <OurStoriesBlock heading={ t("Read More") } postEdges={ pageContext.postEdges } />
                </div>
                <WhatWeCanDoForYouBlock />
            </section>
        </>
    );
};

export default forwardRef(Blog);
