import React from "react";

import { GatsbyImage } from "gatsby-plugin-image";

import { DetailedHTMLProps } from "react";
import { HTMLAttributes } from "react";
import { IGatsbyImageData } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { Ref } from "react";

import clsx from "clsx";

import { graphql } from "gatsby";
import { useStaticQuery } from "gatsby";

import { forwardRef } from "react";
import { useTranslation } from "react-i18next";

import EyeIcon from "@Js/Component/Icon/EyeIcon";
import Heading from "@Js/Component/Heading";
import LinkButton from "@Js/Component/Button/LinkButton";
import ReadingTime from "@Js/Component/ReadingTime";
import SmallArrowRightIcon from "@Js/Component/Icon/SmallArrowRightIcon";

import * as classNames from "@Css/Component/Block/OurStoriesBlock.module.scss";

/**
 * @type WordpressPageData
 */
type WordpressPageData = {
    wordpressPage: {
        uri: string;
    };
};

/**
 * @type OurStoriesBlockProps
 */
export type OurStoriesBlockProps = Omit<DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement>, "children"> & {
    heading?: string;
    postEdges: {
        node: {
            content?: string;
            id: string;
            postDetails: {
                employee?: {
                    featuredImage?: {
                        node: {
                            localFile: {
                                childImageSharp: {
                                    gatsbyImageData: IGatsbyImageData;
                                };
                            };
                        };
                    };
                };
                thumbnail?: {
                    localFile: {
                        childImageSharp: {
                            gatsbyImageData: IGatsbyImageData;
                        };
                    };
                };
            };
            title: string;
            uri: string;
        };
    }[];
};

/**
 * @const OurStoriesBlock
 */
const OurStoriesBlock = (props: OurStoriesBlockProps, ref: Ref<HTMLElement>) => {
    const {
        className,
        heading,
        postEdges,
        ...restProps
    } = props;

    const { t } = useTranslation();

    const data: WordpressPageData = useStaticQuery(graphql`
        query {
            wordpressPage(isBlogsPage: { eq: true }) {
                uri
            }
        }
    `);

    return (
        <section { ...restProps } className={ clsx(classNames.ourStoriesBlock, className) } ref={ ref }>
            <div className={ classNames.container }>
                <Heading>{ heading || t("Our Stories") }</Heading>
                { postEdges.length > 0 && (
                    <ul className={ classNames.list }>
                        { postEdges.map((edge) => (
                            <li className={ classNames.listItem } key={ edge.node.id }>
                                <Link className={ classNames.link } to={ `/blog${ edge.node.uri }` }>
                                    { edge.node.postDetails.thumbnail && (
                                        <GatsbyImage
                                            alt={ edge.node.title }
                                            className={ classNames.image }
                                            image={ edge.node.postDetails.thumbnail.localFile.childImageSharp.gatsbyImageData }
                                        />
                                    ) }
                                    <div className={ classNames.overlay } />
                                    <section className={ classNames.container }>
                                        <Heading className={ classNames.heading }>{ edge.node.title }</Heading>
                                        <ReadingTime className={ classNames.readingTime } content={ edge.node.content || "" } />
                                    </section>
                                    { edge.node.postDetails.employee && edge.node.postDetails.employee.featuredImage && (
                                        <GatsbyImage
                                            alt={ edge.node.title }
                                            className={ classNames.employeeImage }
                                            image={ edge.node.postDetails.employee.featuredImage.node.localFile.childImageSharp.gatsbyImageData }
                                        />
                                    ) }
                                    <div className={ classNames.iconWrapper }>
                                        <EyeIcon className={ classNames.icon } />
                                    </div>
                                </Link>
                            </li>
                        )) }
                    </ul>
                ) }
                <div className={ classNames.buttonWrapper }>
                    <LinkButton className={ classNames.button } to={ data.wordpressPage.uri } variant="outlined">
                        <span>{ t("All blogs") }</span>
                        <SmallArrowRightIcon className={ classNames.icon } />
                    </LinkButton>
                </div>
            </div>
        </section>
    );
};

export default forwardRef(OurStoriesBlock);
